import React from 'react'
import Layout from '../../components/layout';
import Helmet from "react-helmet";

const Webinars = () => {
    return (
        <Layout>
            <Helmet>
                <title>HR Webinars</title>

                <meta name="description" content="We bring you HR webinars to discover the latest HR trends, practical advice on diverse HR and business-related topics from experts and thought leaders all over the world." />

                <meta property="og:title" content="HR Webinars" />

                <meta property="og:description" content="We bring you HR webinars to discover the latest HR trends, practical advice on diverse HR and business-related topics from experts and thought leaders all over the world." />

                <meta property="twitter:title" content="HR Webinars" />
                        
                <meta property="twitter:description" content="We bring you HR webinars to discover the latest HR trends, practical advice on diverse HR and business-related topics from experts and thought leaders all over the world." />
                <meta property="og:type" content="website"/>       
                <meta property="og:url" content="https://www.vantagecircle.com/webinars/"/>
                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/webinars/"></link>
            </Helmet>

            <section className="bg-indigo-100  mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-20 relative">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-7xl text-center lg:text-left px-6 pb-10">
                   <div className = "flex flex-col w-full lg:w-1/2 justify-center lg:justify-start">
                        <h1 className = "text-2xl sm:text-3xl lg:text-7xl text-gray-100 font-bold">HR Webinars</h1>
                        <p className = "section-subtitle-white mt-3">Our HR webinar recordings library is here to help you build a happy, productive, and engaging work culture. Discover HR trends and practical advice on diverse HR and business-related topics from experts and thought leaders all over the world in this space. </p>
                   </div>
                   <div className = "w-full lg:w-1/2 flex justify-center md:justify-end items-center webinar-logo">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/12/webinar-hero.png" width="276" height="276" className = "" />
                    </div>
                </div>
            </section>
            <section className="py-10 md:py-20">
                <div className="container px-6">
                    <h2 className="section-title text-center mb-10">
                        Explore Webinar Playlists
                    </h2>
                </div>
                <div className="container max-w-7xl mx-auto px-6">
                    <div className="md:flex relative p-5 card-1 w-full rounded-xl overflow-hidden shadow-lg mb-10">
                        <div className="rounded-sm bg-indigo-100 md:w-1/2 flex items-center justify-center">
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1708925459/gatsbycms/uploads/2024/02/dave-ulrich1.png" alt="Vantage Point - In Coversation with Dave Ulrich" />
                        </div>
                        <div class="py-6 pb-2 h-full md:w-1/2 px-5 md:px-10">
                            <h2 class="section-title font-bold mb-3">Vantage Point - In Coversation with Dave Ulrich</h2>
                            <p>Join us on an enlightening journey through the complexities of employee recognition, organizational culture, and engagement. In this compelling podcast series, we explore the trio that underpins success in any organization, unveiling essential elements crucial for fostering a thriving workplace environment. Tune in and elevate your organizational skills!</p>
                            <div class="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                <div class="">
                                    <a class="vc-ghost-btn" href="/webinars/vantage-point-webcast-with-dave-ulrich/">Explore Episodes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex relative p-5 card-1 w-full rounded-xl overflow-hidden shadow-lg mb-10">
                        <div className="pt-10 rounded-sm bg-indigo-100 md:w-1/2 flex items-center justify-center">
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1678171657/gatsbycms/uploads/2023/03/webinar-season2-thumbnail.png" alt="Vantage Point Webcasts USA Season2" />
                        </div>
                        <div class="py-6 pb-2 h-full md:w-1/2 px-5 md:px-10">
                            <h2 class="section-title font-bold mb-3 line-clamp-2">Vantage Point Webcasts USA - Season 2</h2>
                            <p>We bring you our new exclusive webcast series called "Vantage Point", hosted by renowned author and executive coach Chester Elton and Adrian Gostick. The webcast would bring top corporate leaders from the HR space. With this webcast series, we intend to address the relevant and trending HR and leadership issues from a 'Vantage Point' and to provide insight and solutions for the audience.</p>
                            <div class="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                <div class="">
                                    <a class="vc-ghost-btn" href="/webinars/vantage-point-webcast-usa-s2/">Explore Episodes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex relative p-5 card-1 w-full rounded-xl overflow-hidden shadow-lg mb-10">
                        <div className="pt-10 rounded-sm bg-indigo-100 md:w-1/2 flex items-center justify-center">
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1652174258/gatsbycms/uploads/2022/08/Kaustabh-webcast-thumbnail.png" alt="Vantage Point Webcasts India" />
                        </div>
                        <div class="py-6 pb-2 h-full md:w-1/2 px-5 md:px-10">
                            <h2 class="section-title font-bold mb-3 line-clamp-2">Vantage Point Webcasts - India</h2>
                            <p>We bring you our new exclusive webcast series called "Vantage Point", hosted by renowned author and mentor of change, Kaustubh Sonalkar. The webcast would bring top thought leaders and industry experts of the HR space together to address the relevant and trending HR and leadership issues from a 'Vantage Point' and to provide insight and solutions for the audience.</p>
                            <div class="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                <div class="">
                                    <a class="vc-ghost-btn" href="/webinars/vantage-point-webcast-in/">Explore Episodes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex relative p-5 card-1 w-full rounded-xl overflow-hidden shadow-lg mb-10">
                        <div className="pt-10 rounded-sm bg-indigo-100 md:w-1/2 flex items-center justify-center">
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1652174258/gatsbycms/uploads/2022/05/vantage-point-thumbnail.webp" alt="Vantage Point Webcasts USA" />
                        </div>
                        <div class="py-6 pb-2 h-full md:w-1/2 px-5 md:px-10">
                            <h2 class="section-title font-bold mb-3 line-clamp-2">Vantage Point Webcasts - USA</h2>
                            <p>We bring you our new exclusive webcast series called "Vantage Point", hosted by renowned author and executive coach Chester Elton and Adrian Gostick. The webcast would bring top corporate leaders from the HR space. With this webcast series, we intend to address the relevant and trending HR and leadership issues from a 'Vantage Point' and to provide insight and solutions for the audience.</p>
                            <div class="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                <div class="">
                                    <a class="vc-ghost-btn" href="/webinars/vantage-point-webcast-usa/">Explore Episodes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex relative p-5 card-1 w-full rounded-xl overflow-hidden shadow-lg mb-10">
                        <div className="p-5 rounded-sm bg-indigo-100 md:w-1/2">
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/nine-speakers.png" alt="Preparedness for VUCA(P) Times" />
                        </div>
                        <div class="py-6 pb-2 h-full md:w-1/2 px-5 md:px-10">
                            <h2 class="section-title font-bold mb-3 line-clamp-2">Preparedness for VUCA(P) Times</h2>
                            <p>The Vantage Circle Webinar Series on VUCA(P) Preparedness is an educational webinar series covering the solutions to a current global problem —Volatility, Uncertainty, Complexity, Ambiguity, and Pandemic, or in short, VUCA(P).</p>
                            <div class="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                <div class="">
                                    <a class="vc-ghost-btn" href="/webinars/vucap-series-2021/">Explore Episodes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex relative p-5 card-1 w-full rounded-xl overflow-hidden shadow-lg mb-20">
                        <div className="p-5 rounded-sm bg-purple-500 md:w-1/2 flex items-center justify-center">
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/12/vantage-doers-inverted.png" alt="Vantage Doers’ Webinar Series" />
                        </div>
                        <div class="py-6 pb-2 h-full md:w-1/2 px-5 md:px-10">
                            <h2 class="section-title font-bold mb-3 line-clamp-2">Vantage Doers’ Webinar Series</h2>
                            <p>The Vantage Circle Doers Webinar Series has been conceptualized with the assured goal of sharing practical knowledge with HR Practitioners to help them rethink their employee engagement strategies and take care of its implementation based on challenges observed in the HR world on a real-time basis.</p>
                            <div class="mt-5 flex justify-center md:flex-nowrap md:justify-start">
                                <div class="">
                                    <a class="vc-ghost-btn" href="/webinars/vantage-doers-webinar-series/">Explore Episodes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

        </Layout>
    )
}

export default Webinars